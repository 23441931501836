import Link from 'next/link';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { colours } from 'src/constants/colours';
import type { MenuItem } from 'src/hooks/useUserProfileMenu';
import { styledComponent } from 'src/utils/styled';

const StyledNavLink = styledComponent(Link)<{
    $currentPath: boolean;
    $isLightText: boolean;
}>`
    text-decoration: none;
    color: ${({ $isLightText }) => ($isLightText ? colours.grey.G200 : colours.primary.main)}!important;
    ${({ $currentPath }) => $currentPath && `font-weight: 700 !important;`};
`;

type Props = MenuItem & {
    href: string;
    currentPage: boolean;
    isLightText?: boolean;
};

const NavItem: React.FC<PropsWithChildren<Props>> = ({ href, currentPage, isLightText = false, children }) => (
    <StyledNavLink href={href} $currentPath={currentPage} $isLightText={isLightText}>
        {children}
    </StyledNavLink>
);

export default NavItem;
