import { useMemo } from 'react';
import { useWindowSize } from 'react-use';

import { breakpoints } from 'src/constants/breakpoints';

// TODO: Refactor this hook to use constant breakpoints tabletPortrait, tabletLandscape, desktopSmall
const useBreakpoints = () => {
    const { width } = useWindowSize();

    return useMemo(
        () => ({
            isMobile: width < breakpoints.tabletPortrait,
            isSmallMobile: width < breakpoints.breakPoint450,
            isUnder500: width < breakpoints.breakPoint500,
            isTabletPortrait: width >= breakpoints.breakPoint800,
            isTabletLandscape: width <= breakpoints.tabletLandscape,
            isDesktopSmallOrTablet: width < breakpoints.desktopLarge && width >= breakpoints.tabletPortrait,
            isDesktopLarge: width >= breakpoints.desktopLarge,
        }),
        [width],
    );
};

export default useBreakpoints;
