import React from 'react';
import type { ImageLoader } from 'next/image';

import type { AvatarProps } from '@mui/material/Avatar';
import MuiAvatar from '@mui/material/Avatar';
import type { BadgeProps } from '@mui/material/Badge';
import MuiBadge from '@mui/material/Badge';

import type { Props as IconProps } from 'src/components/ui/Icon';
import Icon from 'src/components/ui/Icon';
import theme from 'src/utils/theme';
import Image from 'src/components/ui/Image';

import { colours } from 'src/constants/colours';
import type { Medium } from 'src/types/__generated__/graphql';
import { styledComponent } from 'src/utils/styled';

export interface Props extends Omit<AvatarProps, 'src' | 'alt'> {
    image?: Pick<Medium, 'url'> | undefined;
    alt?: string | null;
    fallbackText?: string | null;
    size?: number;
    isCompany?: boolean;
    isPublisher?: boolean;
    border?: boolean;
    badgeIcon?: IconProps['name'];
    badgeColor?: BadgeProps['color'] | 'transparent';
    badgeSize?: IconProps['size'];
    badgeMargin?: string;
    badgeBorderRadius?: string;
    badgeBorderWidth?: string;
    badgeBorderColor?: string;
    badgeBgColor?: string;
    iconName?: IconProps['name'];
    iconColor?: IconProps['color'];
    loader?: ImageLoader;
}

interface StyledProps {
    $size: number;
    $border: boolean;
    $background?: string;
}

const StyledAvatar = styledComponent(MuiAvatar)<StyledProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: ${({ $background }) => $background};
    ${({ $size }) => `
    width: ${$size}px;
    height: ${$size}px;
    font-size: ${Math.round($size / 2.5)}px
    `};
     ${({ $size }) => `${$size}px`};
     ${({ $border }) =>
         $border &&
         `
        border: 0.1875rem solid ${colours.white.main};
        box-shadow: 0 0.25rem 0.625rem ${colours.greyOpacity.O40};
    `}
    img {
        display: block;
    }
`;

interface BadgeColorProps {
    $badgeSize: string;
    $badgeMargin?: string;
    $badgeBorderRadius?: string;
    $badgeBorderWidth?: string;
    $badgeBorderColor?: string;
    $badgeBgColor?: string;
}

const StyledBadge = styledComponent(MuiBadge)<BadgeColorProps>`
    .MuiBadge-badge {
        ${({ $badgeSize }) => `
            width: ${$badgeSize};
            height: ${$badgeSize};
        `}
        ${({ $badgeBorderRadius }) => $badgeBorderRadius && `border-radius: ${$badgeBorderRadius};`}
        ${({ $badgeBorderColor }) => $badgeBorderColor && `border: solid ${$badgeBorderColor};`}
        ${({ $badgeBorderWidth }) => $badgeBorderWidth && `border-width: ${$badgeBorderWidth};`}
        ${({ $badgeBgColor }) => $badgeBgColor && `background-color: ${$badgeBgColor};`}
        ${({ $badgeMargin }) => $badgeMargin && `margin: ${$badgeMargin};`}
        padding: 0;
        min-width: 0;
        z-index: 0;
    .MuiBadge-anchorOriginBottomRightCircle {
        right: 10%;
        bottom: 25%;
    }
`;

const Avatar: React.FC<Props> = ({
    image,
    alt,
    fallbackText,
    size = 32,
    border,
    isCompany,
    isPublisher,
    badgeIcon,
    badgeColor = 'primary',
    badgeSize = '0.66rem',
    badgeMargin,
    badgeBorderRadius = '1rem',
    badgeBorderWidth,
    badgeBorderColor,
    badgeBgColor,
    iconName,
    iconColor,
    loader,
    ...props
}) => {
    let content;
    let avatarColor;
    if (image) {
        content = (
            <Image
                src={image.url}
                alt={alt ?? 'avatar-image'}
                height={size}
                width={size}
                style={{ objectFit: 'cover' }}
            />
        );
    } else if (iconName) {
        avatarColor = !isPublisher ? theme.palette.primary.main : 'undefined';
        const trim = isPublisher ? 0 : 0.3;

        content = (
            <Icon
                size={`${size / 16 - trim}rem`}
                name={iconName}
                color={iconColor}
                containerStyle={{ background: 'none' }}
            />
        );
    } else if (isCompany) {
        avatarColor = theme.palette.primary.main;
        content = <Icon size={`${size / 32}rem`} name="CompanyAvatarEmpty" />;
    } else {
        content = fallbackText;
    }
    const avatar = (
        <StyledAvatar
            $background={avatarColor}
            variant={isCompany ? 'rounded' : 'circular'}
            $size={size}
            $border={Boolean(border)}
            {...props}
        >
            {content}
        </StyledAvatar>
    );

    if (badgeIcon) {
        return (
            <StyledBadge
                overlap="circular"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={<Icon size="100%" name={badgeIcon} />}
                color={badgeColor !== 'transparent' ? badgeColor : undefined}
                $badgeSize={badgeSize}
                $badgeBgColor={badgeBgColor}
                $badgeBorderColor={badgeBorderColor}
                $badgeBorderRadius={badgeBorderRadius}
                $badgeBorderWidth={badgeBorderWidth}
                $badgeMargin={badgeMargin}
            >
                {avatar}
            </StyledBadge>
        );
    }

    return avatar;
};

export default Avatar;
