import React from 'react';

import type { BadgeProps } from '@mui/material/Badge';
import MuiBadge from '@mui/material/Badge';

import { colours } from 'src/constants/colours';
import { styledComponent } from 'src/utils/styled';

export interface Props extends Omit<BadgeProps, 'color'> {
    color?: BadgeProps['color'] | 'tertiary';
    size?: string;
}

interface BadgeColorProps {
    $color?: Props['color'];
}

const StyledBadge = styledComponent(MuiBadge)<BadgeColorProps & { $size?: string }>`
    .MuiBadge-badge {
        ${({ $size }) =>
            $size &&
            `
            min-width: ${$size};
            height: ${$size};
            border-radius: ${$size};
            font-size: ${Number($size.replace('rem', '')) / 1.75}rem;
            line-height: ${Number($size.replace('rem', '')) / 1.5}rem;
        `}
        ${({ $color }) =>
            $color === 'tertiary' &&
            `
            background-color: ${colours.tertiary.main};
            color: ${colours.white.main};
        `}
    }
`;

const Badge: React.FC<Props> = ({ color = 'primary', size = '1.5rem', children, ...rest }) => (
    <StyledBadge
        overlap="circular"
        {...rest}
        color={color !== 'tertiary' ? color : undefined}
        $color={color}
        $size={!children ? size : undefined}
    >
        {children}
    </StyledBadge>
);

export default Badge;
