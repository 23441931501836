import Badge from 'src/components/ui/Badge';
import { colours } from 'src/constants/colours';
import { styledComponent } from 'src/utils/styled';

export const HeaderBadge = styledComponent(Badge)<{
    $isMoreThanMaxNotifications: boolean;
}>`
    .MuiBadge-badge {
        ${({ $isMoreThanMaxNotifications }) => ($isMoreThanMaxNotifications ? `width: 1.5rem;` : `width: 1rem;`)}
        height: 1.2rem;
        border-radius: 1rem;
        font-size: 0.6rem;
        line-height: 0.875rem;
        border: solid 0.1rem;
        border-color: ${colours.white.main};
    }
`;
