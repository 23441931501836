import type { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

import { HeaderNotificationsIcon } from 'src/components/header/HeaderNotificationsIcon';
import Logo from 'src/components/other/Logo';
import Avatar from 'src/components/ui/Avatar';
import type { Props as ButtonProps } from 'src/components/ui/Button';
import Button from 'src/components/ui/Button';
import Drawer from 'src/components/ui/Drawer';
import HamburgerMenuIcon from 'src/components/ui/HamburgerMenuIcon';
import Icon from 'src/components/ui/Icon';
import NavMenu from 'src/components/ui/NavMenu';
import { breakpoints } from 'src/constants/breakpoints';
import { colours } from 'src/constants/colours';
import { headerHeight } from 'src/constants/misc';
import {
    routePathAd,
    routePathCommunity,
    routePathContacts,
    routePathFavorites,
    routePathLogin,
    routePathNews,
    routePathNotifications,
    routePathSelectProfileType,
} from 'src/constants/router';
import { useCountsQuery } from 'src/hooks/__generated__/queries';
import useAppRouter from 'src/hooks/useAppRouter';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useUser from 'src/hooks/useUser';
import useUserProfileMenu from 'src/hooks/useUserProfileMenu';
import type { Medium } from 'src/types/__generated__/graphql';
import { AnalyticsEvent } from 'src/utils/analytics';
import { logEvent } from 'src/utils/firebase';
import { styledComponent, styledTag } from 'src/utils/styled';
import theme from 'src/utils/theme';
import NavItem from '../ui/NavItem';

const HeaderChatIcon = dynamic(() => import('src/components/header/HeaderChatIcon').then((mod) => mod.HeaderChatIcon), {
    ssr: false,
});

// Custom Components
const Container = styledTag('header')<{
    $isProApp: boolean;
}>`
    grid-area: header;
    background-color: ${({ $isProApp }) => ($isProApp ? colours.primary.main : theme.palette.background.paper)};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5rem;
    padding-right: 5rem;
    font-size: 1rem;
    height: ${headerHeight};
    position: relative;
    z-index: 10;
    box-shadow: 0 0.25rem 0.25rem 0 ${colours.lightShadowColor};
    max-width: 100vw;

    @media screen and (max-width: ${breakpoints.desktopLarge - 1}px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media screen and (max-width: 1100px) {
        .left-items {
            margin-left: 3rem;
        }
    }

    @media screen and (max-width: ${breakpoints.tabletPortrait - 1}px) {
        padding-left: 0.5rem;
        padding-right: 1rem;
    }
`;

const IconContainer = styledTag('div')`
    display: flex;
`;

const StyledMenu = styledComponent(Menu)`
    .MuiMenu-paper {
        left: 1rem !important;
    }
    .MuiMenu-list {
        padding: 0.5rem;

        .header-button {
            margin-bottom: 0.375rem;
        }
    }
`;

interface LeftItem {
    href: string;
    label: string;
    name: string;
    onClick: () => void;
}

interface HeaderLeftItemProps extends BoxProps {
    item: LeftItem;
    isLightText?: boolean;
    onClick?: () => void;
}

const HeaderLeftItem: React.FC<HeaderLeftItemProps> = (props) => {
    const router = useAppRouter();
    const { item, isLightText, ...rest } = props;

    return (
        <Box data-target="header-button" data-target-id={`header-${item.name}`} className="header-button" {...rest}>
            <NavItem href={item.href} currentPage={router.pathname === item.href} isLightText={isLightText}>
                {item.label}
            </NavItem>
        </Box>
    );
};

const Header: React.FC = () => {
    const { t } = useTranslation(['common']);
    const user = useUser();
    const userMenuItems = useUserProfileMenu();
    const loggedInUser = Boolean(user);
    const router = useAppRouter();
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [mobileMainMenuDrawerOpen, setMobileMainMenuDrawerOpen] = useState(false);
    const [mobileLoginDrawerOpen, setMobileLoginDrawerOpen] = useState(false);
    const [mobileProfileDrawerOpen, setMobileProfileDrawerOpen] = useState(false);

    const { isMobile, isSmallMobile, isTabletPortrait, isTabletLandscape, isDesktopLarge } = useBreakpoints();

    const displayHeaderItemWhenUserLoggged = isTabletPortrait && loggedInUser;
    const displayHeaderItemWhenUserUnlogged = isDesktopLarge && !loggedInUser;
    const isProApp = Boolean(loggedInUser && user?.isProfessionnal);
    const leftItems: LeftItem[] = [
        {
            href: routePathNews,
            label: t('common:header.news'),
            name: 'news',
            onClick: () => logEvent(AnalyticsEvent.ClickNews),
        },
        {
            href: routePathAd,
            label: t('common:header.realEstate'),
            name: 'real-estate',
            onClick: () => logEvent(AnalyticsEvent.ClickEstateSearch),
        },
        {
            href: routePathCommunity,
            label: t('common:header.community'),
            name: 'community',
            onClick: () => logEvent(AnalyticsEvent.ClickCommunity),
        },
    ];

    const profileIconLarge = <Icon name="User" size="2rem" color={colours.black.main} />;

    const onCloseDrawer = () => {
        setMobileLoginDrawerOpen(false);
        setMobileMainMenuDrawerOpen(false);
        setMobileProfileDrawerOpen(false);
    };

    const onClickHamburgerMenuMobile = () => {
        setMobileMainMenuDrawerOpen(!mobileMainMenuDrawerOpen);
        setMobileLoginDrawerOpen(false);
        setMobileProfileDrawerOpen(false);
    };

    const onOpenHamburgerMenuTablet = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const onCloseHamburgerMenuTablet = () => {
        setMenuAnchorEl(null);
    };

    const onClickLoginIconMobile = () => {
        setMobileLoginDrawerOpen(!mobileLoginDrawerOpen);
        setMobileMainMenuDrawerOpen(false);
    };

    const onClickProfileIconMobile = () => {
        setMobileProfileDrawerOpen(!mobileProfileDrawerOpen);
        setMobileMainMenuDrawerOpen(false);
    };

    const { data } = useCountsQuery({ skip: !user });
    const totalPendingNotifications = data?.counts.totalPendingNotifications
        ? Number(data.counts.totalPendingNotifications)
        : 0;

    const loggedInItems = [
        {
            href: routePathNotifications,
            activeComponent: (
                <HeaderNotificationsIcon
                    totalPendingNotifications={totalPendingNotifications}
                    isProApp={isProApp}
                    isActive
                />
            ),
            inactiveComponent: (
                <HeaderNotificationsIcon
                    totalPendingNotifications={totalPendingNotifications}
                    isProApp={isProApp}
                    isActive={false}
                />
            ),
        },
        {
            href: routePathContacts,
            activeComponent: <HeaderChatIcon isProApp={isProApp} isActive />,
            inactiveComponent: <HeaderChatIcon isProApp={isProApp} isActive={false} />,
        },
        {
            href: routePathFavorites,
            activeComponent: <Icon name={isProApp ? 'BookmarkWhiteActive' : 'BookmarkPrimaryActive'} size="1.5rem" />,
            inactiveComponent: (
                <Icon name={isProApp ? 'BookmarkWhiteInactive' : 'BookmarkPrimaryInactive'} size="1.5rem" />
            ),
        },
        {
            component: (
                <NavMenu
                    subMenuItems={userMenuItems}
                    component={
                        user && (
                            <Avatar
                                data-target="header-avatar"
                                data-target-id={`header-${user.id}`}
                                alt={user.fullName}
                                image={user.image as Medium}
                                fallbackText={user.initials}
                            />
                        )
                    }
                    isDrawerOpen={mobileProfileDrawerOpen}
                    onCloseDrawer={onCloseDrawer}
                />
            ),
        },
    ];

    const loggedOutButtons = [
        {
            label: t('common:header.login'),
            variant: 'outlined',
            href: routePathLogin,
            name: 'login',
        },
        {
            label: t('common:header.signUp'),
            color: 'primary',
            href: routePathSelectProfileType,
            name: 'signup',
        },
    ];

    return (
        <Container $isProApp={isProApp}>
            {!displayHeaderItemWhenUserLoggged && !displayHeaderItemWhenUserUnlogged && (
                <button
                    type="button"
                    onClick={isMobile ? onClickHamburgerMenuMobile : onOpenHamburgerMenuTablet}
                    style={{ all: 'unset' }}
                >
                    <HamburgerMenuIcon open={mobileMainMenuDrawerOpen} isProApp={isProApp} />
                </button>
            )}
            {(mobileMainMenuDrawerOpen && (
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                <Drawer isOpen={mobileMainMenuDrawerOpen} onCloseDrawer={onCloseDrawer}>
                    {leftItems.map((item, index) => (
                        <HeaderLeftItem
                            key={`loggedInItems-${index}`}
                            item={item}
                            p="2rem 1rem 0rem"
                            onClick={item.onClick}
                        />
                    ))}
                </Drawer>
            )) ||
                (Boolean(menuAnchorEl) && (
                    <StyledMenu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={Boolean(menuAnchorEl)}
                        onClose={onCloseHamburgerMenuTablet}
                        keepMounted
                    >
                        {leftItems.map((item, index) => (
                            <HeaderLeftItem key={`loggedInItems-${index}`} item={item} onClick={item.onClick} />
                        ))}
                    </StyledMenu>
                ))}
            {isSmallMobile && loggedInUser ? (
                <Logo isProLogo={isProApp} width="2.5rem" height="2.5rem" isWithoutText />
            ) : (
                <Logo
                    isProLogo={isProApp}
                    width={isProApp ? '8.5rem' : undefined}
                    height={isProApp ? '3.825rem' : undefined}
                />
            )}
            <Box
                flex="1"
                ml="5.5rem"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                className="left-items"
            >
                <Box display="flex" flexDirection="row" alignItems="center">
                    {(displayHeaderItemWhenUserLoggged || displayHeaderItemWhenUserUnlogged) &&
                        leftItems.map((item, index) => (
                            <HeaderLeftItem
                                key={`loggedInItems-${index}`}
                                item={item}
                                mr="3rem"
                                isLightText={isProApp}
                                onClick={item.onClick}
                            />
                        ))}
                </Box>
                {loggedInUser ? (
                    <Box display="flex" flexDirection="row" alignItems="center">
                        {loggedInItems.map(({ href, component, activeComponent, inactiveComponent }, index) => (
                            <Box
                                key={`loggedInItems-${index}`}
                                ml={isTabletLandscape ? '1.5rem' : '2rem'}
                                onClick={isMobile ? onClickProfileIconMobile : () => undefined}
                            >
                                {component ?? (
                                    <NavItem href={href} currentPage={router.asPath === href} isLightText={isProApp}>
                                        {router.asPath === href ? activeComponent : inactiveComponent}
                                    </NavItem>
                                )}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="row" alignItems="center">
                        {!isMobile ? (
                            loggedOutButtons.map(({ label, ...props }) => (
                                <Box key={props.href} ml="1.5rem">
                                    <Button
                                        data-target="header-button"
                                        data-target-id={`header-${props.name}`}
                                        {...(props as Partial<ButtonProps>)}
                                    >
                                        {label}
                                    </Button>
                                </Box>
                            ))
                        ) : (
                            <>
                                <IconContainer onClick={onClickLoginIconMobile}>{profileIconLarge}</IconContainer>
                                {mobileLoginDrawerOpen && (
                                    <Drawer isOpen={mobileLoginDrawerOpen} onCloseDrawer={onCloseDrawer}>
                                        {loggedOutButtons.map(({ label, ...props }) => (
                                            <Box key={props.href} p="1rem 1rem 0rem" display="flex" width="100%">
                                                <Button
                                                    data-target="header-button"
                                                    data-target-id={`header-${props.name}`}
                                                    fullWidth
                                                    {...(props as Partial<ButtonProps>)}
                                                >
                                                    {label}
                                                </Button>
                                            </Box>
                                        ))}
                                    </Drawer>
                                )}
                            </>
                        )}
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default Header;
