import React from 'react';

import { colours } from 'src/constants/colours';
import { styledTag } from 'src/utils/styled';

interface Props {
    open: boolean;
    isProApp: boolean;
}

const HamburgerMenuContainer = styledTag('div')`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    -ms-transform: scale(0.4);
    transform: scale(0.4);
`;

const HamburgerIcon = styledTag('div')<{
    $open: boolean;
    $isProApp: boolean;
}>`
    width: 3.125rem;
    height: ${({ $isProApp }) => ($isProApp ? '0.313rem' : '0.188rem')};
    background: ${({ $isProApp }) => ($isProApp ? colours.lightGray : colours.primary.dark)};
    border-radius: 0.313rem;
    transition: all 0.5s ease-in-out;

    ${({ $open }) =>
        $open &&
        `
        transform: translateX(-3.125rem);
        background: transparent;
    `}

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 3.125rem;
        height: ${({ $isProApp }) => ($isProApp ? '0.313rem' : '0.188rem')};
        background: ${({ $isProApp }) => ($isProApp ? colours.lightGray : colours.primary.dark)};
        border-radius: 0.313rem;
        transition: all 0.5s ease-in-out;
    }

    &::before {
        transform: translateY(-1rem);

        ${({ $open }) =>
            $open &&
            `
            transform: rotate(45deg) translate(2.188rem, -2.188rem);
        `}
    }

    &::after {
        transform: translateY(1rem);

        ${({ $open }) =>
            $open &&
            `
            transform: rotate(-45deg) translate(2.188rem, 2.188rem);
        `}
    }
`;

const HamburgerMenuIcon: React.FC<Props> = ({ open, isProApp }) => (
    <HamburgerMenuContainer data-target-id="hamburger-menu">
        <HamburgerIcon $open={open} $isProApp={isProApp} />
    </HamburgerMenuContainer>
);

export default HamburgerMenuIcon;
