import type { Company, ContentType, CompanyProfileQuery } from 'src/types/__generated__/graphql';
import { CompanyLabel } from 'src/types/__generated__/graphql';

export const isContentTypeManageable = (contentType: ContentType, company?: Pick<Company, 'capabilities'>) =>
    company?.capabilities?.allowedContentTypesToManage?.includes(contentType);

export const isContentTypeDisplayable = (contentType: ContentType, company?: Pick<Company, 'capabilities'>) =>
    company?.capabilities?.allowedContentTypesToDisplay?.includes(contentType);

export const isCompanyLabelAllowed = (companyLabel: CompanyLabel, company?: Pick<Company, 'capabilities'>) =>
    company?.capabilities?.allowedLabels?.includes(companyLabel);

/**
 * Returns the company title translation key depending on company type (company or group)
 */
export const getCompanyTitleTKey = (company: Company | CompanyProfileQuery['companyProfile']) => {
    const isGroup = isCompanyLabelAllowed(CompanyLabel.Group, company);

    return isGroup ? 'company:groupTitle' : 'company:company';
};
