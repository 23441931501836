import React from 'react';
import dynamic from 'next/dynamic';

import type { LinkProps } from 'src/components/ui/Link';
import Link from 'src/components/ui/Link';
import { routePathHome } from 'src/constants/router';
import { styledComponent, styledTag } from 'src/utils/styled';

const LogoIcon = dynamic(() => import('src/assets/logos/dark.svg'), { ssr: false });
const LogoIconPro = dynamic(() => import('src/assets/logos/pro-light.svg'), { ssr: false });
const LogoIconNoText = dynamic(() => import('src/assets/logos/logo-withouttext.svg'), {
    ssr: false,
});
const LogoIconNoTextPro = dynamic(() => import('src/assets/logos/logo-withouttext-dark.svg'), {
    ssr: false,
});

const LogoContainer = styledComponent((props: LinkProps) => <Link {...props} />)`
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
        height: auto;
    }
`;

const LogoContainerNotClickable = styledTag('div')`
    display: flex;
    align-items: center;
    cursor: default;
    svg {
        height: auto;
    }
`;

type Props = {
    onClick?: () => void;
    isProLogo?: boolean;
    width?: string;
    height?: string;
    isClickable?: boolean;
    isWithoutText?: boolean;
};

const Logo: React.FC<Props> = ({
    isProLogo = false,
    onClick,
    width = '8.2rem',
    height = '3.125rem',
    isClickable = true,
    isWithoutText = false,
}) => {
    if (isClickable && isWithoutText) {
        return (
            <LogoContainer onClick={onClick} href={routePathHome}>
                {isProLogo ? (
                    <LogoIconNoTextPro width={width} height={height} />
                ) : (
                    <LogoIconNoText width={width} height={height} />
                )}
            </LogoContainer>
        );
    } else if (isClickable) {
        return (
            <LogoContainer onClick={onClick} href={routePathHome}>
                {isProLogo ? <LogoIconPro width={width} height={height} /> : <LogoIcon width={width} height={height} />}
            </LogoContainer>
        );
    } else if (isWithoutText) {
        return (
            <LogoContainerNotClickable>
                {isProLogo ? (
                    <LogoIconNoTextPro width={width} height={height} />
                ) : (
                    <LogoIconNoText width={width} height={height} />
                )}
            </LogoContainerNotClickable>
        );
    } else {
        return (
            <LogoContainerNotClickable>
                {isProLogo ? <LogoIconPro width={width} height={height} /> : <LogoIcon width={width} height={height} />}
            </LogoContainerNotClickable>
        );
    }
};

export default Logo;
