import React from 'react';

import type { DrawerProps } from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import type { PaperProps } from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { headerHeight } from 'src/constants/misc';
import { styledComponent, styledTag } from 'src/utils/styled';

const StyledPaper = styledTag('div')`
    height: calc(100% - ${headerHeight}) !important;
    top: ${headerHeight};
`;

interface CustomPaperProps extends PaperProps {
    component?: unknown;
}
interface StyledMuiDrawerProps extends Omit<DrawerProps, 'PaperProps'> {
    PaperProps: CustomPaperProps;
}
const StyledMuiDrawer = styledComponent(MuiDrawer)<StyledMuiDrawerProps>``;

interface Props extends DrawerProps {
    isOpen: boolean;
    children: React.ReactNode;
    onCloseDrawer: () => void;
}

const Drawer: React.FC<Props> = (props) => {
    const { isOpen, children, onCloseDrawer, ...rest } = props;
    return (
        <StyledMuiDrawer
            anchor="bottom"
            open={isOpen}
            onClose={onCloseDrawer}
            PaperProps={{ component: StyledPaper }}
            BackdropProps={{ invisible: true }}
            transitionDuration={50}
        >
            <Box
                width="100vw"
                flex="1"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {children}
            </Box>
        </StyledMuiDrawer>
    );
};

export default Drawer;
