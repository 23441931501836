import { useTranslation } from 'next-i18next';

import useAuthActions from 'src/hooks/useAuthActions';
import useUser from 'src/hooks/useUser';
import type { Props as IconProps } from 'src/components/ui/Icon';
import { isCompanyLabelAllowed } from 'src/utils/companyUtils';
import {
    routePathProfileEstimateStep1,
    routePathProfile,
    routePathProfileAds,
    routePathProfileCompany,
    routePathProfileCompanyInfo,
    routePathProfileInterests,
    routePathProfileNetwork,
    routePathProfilePosts,
    routePathProfileProjects,
    routePathProfileRatings,
    routePathProfileSimulations,
    routePathProfileStatistics,
    routePathProfileCrmSync,
    routePathProfileSecurity,
    routePathProfileHelp,
    routePathProfileLegal,
} from 'src/constants/router';
import type { Company } from 'src/types/__generated__/graphql';
import { CompanyLabel } from 'src/types/__generated__/graphql';

export type MenuItem = {
    href?: string;
    label?: string;
    iconName?: IconProps['name'];
    dividerLabel?: string;
    action?: () => void;
};
const useUserProfileMenu = (): MenuItem[] => {
    const currentUser = useUser();
    const { t } = useTranslation(['common']);
    const { signOut } = useAuthActions();
    const companyGroup = isCompanyLabelAllowed(CompanyLabel.Group, currentUser?.affiliatedCompany as Company);

    const items = [
        {
            href: routePathProfile,
            label: t('common:header.profile'),
            iconName: 'User',
        },
        currentUser?.isAdminOfAffiliatedCompany
            ? {
                  href: routePathProfileCompanyInfo,
                  label: companyGroup ? t('common:groupManagement') : t('common:header.companyManagement'),
                  iconName: 'SettingCompany',
              }
            : undefined,
        {
            dividerLabel: t('common:header.activity'),
        },
        {
            href: routePathProfileNetwork,
            label: t('common:header.myNetwork'),
            iconName: 'Network',
        },
        currentUser?.isProfessionnal && !currentUser.isAdminOfAffiliatedCompany
            ? {
                  href: routePathProfileCompany,
                  label: t('common:header.myCompany'),
                  iconName: 'OfficeWithoutGradient',
              }
            : undefined,
        currentUser?.isProfessionnal
            ? {
                  href: routePathProfilePosts,
                  label: t('common:header.posts'),
                  iconName: 'News',
              }
            : undefined,
        currentUser?.isCrafts
            ? {
                  href: routePathProfileProjects,
                  label: t('common:header.projects'),
                  iconName: 'Projects',
              }
            : undefined,
        currentUser?.isRealEstatesTrades
            ? {
                  href: routePathProfileAds,
                  label: t('common:header.realestate'),
                  iconName: 'RealEstateBuilding',
              }
            : undefined,
        {
            href: routePathProfileInterests,
            label: t('common:header.pointsOfInterest'),
            iconName: 'PointsOfInterest',
        },
        currentUser?.isProfessionnal
            ? {
                  href: routePathProfileRatings,
                  label: t('common:header.ratings'),
                  iconName: 'StarBlank',
              }
            : undefined,
        {
            dividerLabel: t('common:header.tools'),
        },
        currentUser?.isRealEstatesTrades
            ? {
                  href: routePathProfileStatistics,
                  label: t('common:header.statistic'),
                  iconName: 'Statistic',
              }
            : undefined,
        !currentUser?.isProfessionnal
            ? {
                  href: routePathProfileAds,
                  label: t('common:header.ads'),
                  iconName: 'NoteAd',
              }
            : undefined,
        {
            href: routePathProfileEstimateStep1,
            label: t('common:header.estimateProperty'),
            iconName: 'Financial',
        },
        !currentUser?.isProfessionnal || currentUser.isRealEstatesTrades
            ? {
                  href: routePathProfileSimulations,
                  label: t('common:header.simulation'),
                  iconName: 'Calculator',
              }
            : undefined,
        {
            dividerLabel: t('common:header.settings'),
        },
        currentUser?.isMandatoryAgentTrade
            ? {
                  href: routePathProfileCrmSync,
                  label: t('common:header.crmSync'),
                  iconName: 'Settings',
              }
            : undefined,
        {
            href: routePathProfileSecurity,
            label: t('common:header.security'),
            iconName: 'NotificationBlackInactive',
        },
        {
            href: routePathProfileHelp,
            label: t('common:header.help'),
            iconName: 'Help',
        },
        {
            href: routePathProfileLegal,
            label: t('common:header.rules'),
            iconName: 'File',
        },
        {
            action: signOut,
            label: t('common:header.logout'),
            iconName: 'Logout',
        },
    ];

    return items.filter((item) => item !== undefined) as MenuItem[];
};

export default useUserProfileMenu;
