import React from 'react';

import { HeaderBadge } from 'src/components/header/HeaderBadge';
import Icon from 'src/components/ui/Icon';
import { maxNotifications } from 'src/constants/misc';

type HeaderNotificationsIconProps = {
    totalPendingNotifications: number;
    isActive?: boolean;
    isProApp?: boolean;
};

export const HeaderNotificationsIcon: React.FC<HeaderNotificationsIconProps> = ({
    totalPendingNotifications,
    isActive,
    isProApp,
}) => {
    const iconNames = {
        active: isProApp ? 'NotificationWhiteActive' : 'NotificationPrimaryActive',
        inactive: isProApp ? 'NotificationWhiteInactive' : 'NotificationPrimaryInactive',
    } as const;

    return (
        <HeaderBadge
            $isMoreThanMaxNotifications={totalPendingNotifications > maxNotifications}
            badgeContent={totalPendingNotifications}
            color="tertiary"
            invisible={!totalPendingNotifications}
            max={maxNotifications}
        >
            <Icon name={isActive ? iconNames.active : iconNames.inactive} size="1.5rem" />
        </HeaderBadge>
    );
};
